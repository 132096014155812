<template>
  <div class="bitcoin">
    <div v-if="tweenedNumber">
      1 BTC =
      {{ tweenedNumber.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }}
      €
      <hr />
      <input
        class="amount-input"
        type="number"
        v-model="amount"
        max="1000000"
        maxlength="10"
        step="0.00000001"
      />
      <div class="res">
        {{
          (tweenedNumber * amount)
            .toFixed(2)
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        }}
        €
      </div>
    </div>
    <div v-if="!myBtc.price">
      Waiting for websocket connection ...
    </div>
    <div class="calculator">
      <div class="cell" @click="addNumber(7)">7</div>
      <div class="cell" @click="addNumber(8)">8</div>
      <div class="cell" @click="addNumber(9)">9</div>
      <div class="cell" @click="addNumber(4)">4</div>
      <div class="cell" @click="addNumber(5)">5</div>
      <div class="cell" @click="addNumber(6)">6</div>
      <div class="cell" @click="addNumber(1)">1</div>
      <div class="cell" @click="addNumber(2)">2</div>
      <div class="cell" @click="addNumber(3)">3</div>
      <div class="cell" @click="addNumber(0)">0</div>
      <div class="cell" @click="addDot()">.</div>
      <div class="cell" @click="cancel()">C</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import gsap from "gsap";
export default {
  name: "Bitcoin",
  data() {
    return {
      patr: 1050,
      amount: 1,
      addedDot: false,
      value: 0,
      tweenedNumber: 0
    };
  },
  methods: {
    ...mapActions(["initWebsocket"]),
    addNumber(number) {
      if (number === 0 && this.amount === 0) {
        this.amount = 0;
      } else if (this.amount === 0) {
        this.amount = number;
      } else {
        this.amount = this.amount + "" + number;
      }
    },
    cancel() {
      this.amount = 0;
      this.addedDot = false;
    },
    addDot() {
      if (!this.addedDot) {
        this.amount = this.amount + ".";
        this.addedDot = true;
      }
    }
  },
  computed: mapGetters(["myBtc"]),
  created() {
    this.initWebsocket();
    if (this.myBtc) {
      this.tweenedNumber = this.myBtc.price;
    } else {
      this.tweenedNumber = 0;
    }
  },
  updated() {
    if (this.myBtc) {
      gsap.to(this.$data, {
        duration: 1,
        case: "circ.out",
        tweenedNumber: this.myBtc.price
      });
    }
  }
};
</script>

<style scoped>
.amount-input {
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  border: 4px solid cornsilk;
  border-radius: 25px;
  outline-style: none;
  background-color: #f15946;
}
.res {
  font-size: 42px;
  text-align: center;
  padding-top: 42px;
}
.calculator {
  width: 240px;
  height: 320px;
  display: grid;
  grid-template-columns: 80px 80px 80px;
  grid-template-rows: 80px 80px 80px 80px;
  background-color: cornsilk;
  margin: 0 auto;
}
.cell {
  height: 80px;
  width: 80px;
  text-align: center;
  font-size: 65px;
  font-weight: bolder;
  color: black;
  border: 1px solid black;
  cursor: pointer;
  vertical-align: middle;
}
.cell:hover {
  background-color: #f15946;
  outline-style: none;
}
</style>
